import mixins from '#/app/styles';
import { StyleSheet } from 'react-native';

export const tableStyles = StyleSheet.create({
  transactionRow: {
    flexDirection: 'row',
    color: mixins.color.white,
    paddingHorizontal: 15,
    paddingVertical: 3,
    borderBottomColor: mixins.color.grayDark,
    borderBottomWidth: 1,
    borderStyle: 'solid',
  },
  transactionHeadingRow: {
    backgroundColor: mixins.color.dark,
    paddingVertical: 8,
    borderBottomWidth: 0,
    marginBottom: 5,
  },
  columnDate: { flexBasis: 220, color: mixins.color.white },
  columnDescription: { flexBasis: 250, flexGrow: 1, flexShrink: 1, color: mixins.color.white },
  columnValue: { flexBasis: 80, textAlign: 'right', color: mixins.color.white },
  columnBalance: { flexBasis: 80, textAlign: 'right', color: mixins.color.white },
});
