import { ActiveTariff } from '../../api/types';
import React, { useContext, useState } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import Button from '../../components/Button/Button';
import DefaultModal from '../../components/DefaultModal/DefaultModal';
import mixins from '../../app/styles';
import { paymentMethodMap } from '../../helpers/consts';
import dayjs from 'dayjs';
import { useBillingPortalUrlRequest, useCancelTariffRenewalRequest } from '../../api/api.portal';
import { ToasterContext } from '../../toaster/toaster.provider';
import { useQueryClient } from '@tanstack/react-query';
import handleLink from '#/helpers/handle-link';
import { navigationRef } from '#/navigation/navigation.ref';

const styles = StyleSheet.create({
  currentSubscriptionBox: {
    width: '100%',
    maxWidth: '100%',
    flexShrink: 1,
    flexGrow: 1,
    backgroundColor: mixins.color.blueDark,
    borderRadius: 10,
    paddingVertical: 10,
    paddingHorizontal: 20,
  },
  value: {
    color: mixins.color.white,
    minWidth: 120,
    fontSize: 16,
  },
  modalText: {
    color: mixins.color.whiteText,
    fontSize: 16,
    textAlign: 'center',
    marginVertical: 'auto',
  },
  buttonRow: {
    marginTop: 15,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    gap: 10,
  },
});

const CurrentTariffInfoBox = ({ tariff }: { tariff?: ActiveTariff }) => {
  const [showConfirmCancelRenewal, setShowConfirmCancelRenewal] = useState(false);
  const [cancelRenewal] = useCancelTariffRenewalRequest();
  const billingPortalUrl = useBillingPortalUrlRequest();
  const queryClient = useQueryClient();
  const { error, success } = useContext(ToasterContext);

  const handleCancelRenewal = async () => {
    try {
      await cancelRenewal();
      await queryClient.refetchQueries(['me/tariff']);
      success({ message: 'Renewal canceled successfully' });
    } catch (err) {
      error({ message: 'Could not cancel renewal' });
    } finally {
      setShowConfirmCancelRenewal(false);
    }
  };

  const handlePressBillingPortal = async () => {
    const response = await billingPortalUrl.mutateAsync();

    handleLink(response.data.url, '_blank');
  };

  if (!tariff || !tariff.validTo) {
    return null;
  }

  return (
    <>
      <View style={styles.currentSubscriptionBox}>
        {tariff.willRenew ? (
          <>
            <Text style={styles.value}>
              Your current subscription will be automatically renewed{' '}
              <strong>in {dayjs(tariff.validTo).diff(dayjs(), 'day')} days</strong> (
              {dayjs(tariff.validTo).format('D MMM, YYYY HH:mm')})
              {tariff.paymentMethod === 'balance' && ' if there are enough funds on your balance.'}
            </Text>
            <Text style={[styles.value, mixins.styles.margin.topI3]}>
              Your current payment method is{' '}
              <strong>{paymentMethodMap[tariff.paymentMethod]}</strong>
            </Text>
            <View style={styles.buttonRow}>
              <Button onPress={() => setShowConfirmCancelRenewal(true)}>
                Cancel automatic renewal
              </Button>
              {tariff.paymentMethod === 'balance' && (
                <Button
                  onPress={() => navigationRef.current?.navigate('Tariff', { openTopUp: true })}
                >
                  Add funds
                </Button>
              )}
              {(tariff.paymentMethod === 'card' || tariff.paymentMethod === 'paypal') && (
                <Button loading={billingPortalUrl.isPending} onPress={handlePressBillingPortal}>
                  Manage subscription
                </Button>
              )}
            </View>
          </>
        ) : (
          <Text style={styles.value}>
            Your subscription will expire{' '}
            <strong>in {dayjs(tariff.validTo).diff(dayjs(), 'day')} days</strong> (
            {dayjs(tariff.validTo).format('D MMM, YYYY HH:mm')}) and will not be renewed
            automatically.
          </Text>
        )}
      </View>
      {showConfirmCancelRenewal && (
        <DefaultModal
          confirmText="Cancel renewal"
          dismissText="Do nothing"
          title="Confirm canceling automatic renewal"
          onConfirm={handleCancelRenewal}
          onDismiss={() => setShowConfirmCancelRenewal(false)}
        >
          <Text style={styles.modalText}>
            If you cancel your renewal, you will switch back to basic pricing plan on{' '}
            {dayjs(tariff!.validTo).format('D MMM, YYYY HH:mm')}
          </Text>
        </DefaultModal>
      )}
    </>
  );
};

export default CurrentTariffInfoBox;
