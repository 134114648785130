import Loader from '#/components/Loader/Loader';
import { useMeQuery, useReferralStatsQuery } from '#/api/api.portal';
import mixins from '#/app/styles';
import Heading from '#/components/Heading/Heading';
import PageWrapper from '#/components/PageWrapper/PageWrapper';
import { useSelectedBusinessUserStore } from '#/screens/home/selectedBusinessUser.store';
import { BusinessUserSwitcher } from '#/components/BusinessUserSwitcher/BusinessUserSwitcher';
import { Me } from '#/api/types';
import { ReferAFriendBox } from '#/screens/referAFriend/ReferAFriend';

export const ReferAFriendScreen = () => {
  const { data: profile } = useMeQuery();
  const { selectedUserId } = useSelectedBusinessUserStore();

  if (!profile) {
    return <Loader />;
  }

  return (
    <PageWrapper>
      {selectedUserId ? (
        <BusinessUserSwitcher />
      ) : (
        <Heading style={mixins.styles.margin.topI8}>Refer a friend</Heading>
      )}
      <ProfileContainer profile={profile} />
    </PageWrapper>
  );
};

const ProfileContainer = ({ profile }: { profile: Me }) => {
  const stats = useReferralStatsQuery();

  if (stats.isPending || !stats.data) {
    return <Loader />;
  }

  return <ReferAFriendBox user={profile} stats={stats.data} />;
};
