import { useAddBusinessUserMutation } from '#/api/api.portal';
import { Tariff } from '#/api/types';
import mixins from '#/app/styles';
import DefaultModal from '#/components/DefaultModal/DefaultModal';
import { InputWithLabel } from '#/components/InputWIthLabel/InputWIthLabel';
import Select from '#/components/Select/Select';
import { useEffect, useState } from 'react';
import { StyleSheet } from 'react-native';

interface Props {
  onDismiss: () => void;
  tariffs: Tariff[];
}

const styles = StyleSheet.create({
  addButton: {
    alignSelf: 'flex-start',
    marginTop: mixins.indent.i8,
  },
  actionRow: {
    flexDirection: 'row',
    marginBottom: mixins.indent.i2,
    alignItems: 'center',
  },
  wrapper: {
    paddingRight: mixins.indent.i12,
  },
  headerControls: {
    flexDirection: 'row',
    marginLeft: 'auto',
    alignItems: 'center',
    gap: 10,
  },
  select: {
    paddingVertical: 5,
    backgroundColor: mixins.color.dark,
    borderColor: mixins.color.dark,
  },
  activeView: {
    borderColor: mixins.color.blue,
  },
  listButton: {
    borderWidth: 2,
    borderColor: mixins.color.dark,
    borderStyle: 'solid',
    width: 30,
    height: 30,
    alignItems: 'center',
    justifyContent: 'center',
  },
  tariffDropdown: {
    backgroundColor: mixins.color.dark,
    height: 36,
    borderColor: mixins.color.dark,
    borderBottomWidth: 2,
    borderBottomColor: mixins.color.grayDarkest,
    borderStyle: 'solid',
    width: '100%',
  },
});

const initialValue = {
  login: '',
  password: '',
  tariffId: -1,
  confirmPassword: '',
};

export const AddNewBusinessUserModal: React.FC<Props> = ({ onDismiss, tariffs }) => {
  const [formValues, setFormValues] = useState(initialValue);
  const addBusinessUserMutation = useAddBusinessUserMutation();

  const handleChange = (field: keyof typeof formValues) => (value: string | number) => {
    setFormValues(v => ({ ...v, [field]: value }));
  };

  const handleSubmit = () => {
    addBusinessUserMutation.mutate(formValues, {
      onSuccess: () => {
        setFormValues(initialValue);
        onDismiss();
      },
    });
  };

  useEffect(() => {
    if (formValues.tariffId === -1 && tariffs.length > 0) {
      handleChange('tariffId')(tariffs[0].id);
    }
  }, [tariffs, formValues.tariffId]);

  return (
    <DefaultModal
      title="Add user"
      dismissText="Cancel"
      confirmText={addBusinessUserMutation.isPending ? 'Adding...' : 'Add'}
      onConfirm={addBusinessUserMutation.isPending ? () => undefined : handleSubmit}
      onDismiss={onDismiss}
    >
      <InputWithLabel name="Name" onChange={handleChange('login')} withMargin />
      <InputWithLabel name="Password" onChange={handleChange('password')} secure withMargin />
      <InputWithLabel
        name="Confirm password"
        onChange={handleChange('confirmPassword')}
        withMargin
        tip={
          formValues.password !== formValues.confirmPassword ? "Passwords don't match" : undefined
        }
        secure
        tipError
      />
      <InputWithLabel name="Tariff" onChange={handleChange('tariffId')} withMargin>
        <Select<number>
          value={formValues.tariffId}
          style={styles.tariffDropdown}
          values={(tariffs || []).map(t => ({ label: t.name, value: t.id }))}
          handleChange={handleChange('tariffId')}
        />
      </InputWithLabel>
    </DefaultModal>
  );
};
