import React from 'react';
import styles from './DetailsBox.module.scss';
import dayjs from 'dayjs';

type Props = { data: any };

export const DetailsBox: React.FC<Props> = ({ data }) => {
  const { tariffValidTo, type, registeredAt, willRenew, email, tariffName, paymentByBalance } =
    data;

  return (
    <div className={styles.wrapper}>
      <div className={styles.details}>
        <div className={styles.item}>
          <strong>Email</strong>
          <span>{email}</span>
        </div>
        <div className={styles.item}>
          <strong>Registered</strong>
          <span>{dayjs(registeredAt).format('DD/MM/YYYY')}</span>
        </div>
        <div className={styles.item}>
          <strong>User type</strong>
          <span>{type}</span>
        </div>
        {tariffName && (
          <div className={styles.item}>
            <strong>Tariff</strong>
            <span>{tariffName}</span>
          </div>
        )}
        {willRenew !== undefined && (
          <div className={styles.item}>
            <strong>Will renew tariff</strong>
            <span>{paymentByBalance ? 'Yes' : 'No'}</span>
          </div>
        )}
        {tariffValidTo && (
          <div className={styles.item}>
            <strong>Tariff valid to</strong>
            <span>{dayjs(tariffValidTo).format('DD/MM/YYYY')}</span>
          </div>
        )}
        {paymentByBalance && (
          <div className={styles.item}>
            <strong>Payment by user balance</strong>
            <span>{paymentByBalance ? 'Yes' : 'No'}</span>
          </div>
        )}
      </div>
    </div>
  );
};
