import { userDetailsQuery } from '#/api/api.portal';
import Heading from '#/components/Heading/Heading';
import Loader from '#/components/Loader/Loader';
import PageWrapper from '#/components/PageWrapper/PageWrapper';
import { tableStyles } from '#/screens/adminProfile/adminProfile.styles';
import { DetailsBox } from '#/screens/adminProfile/DetailsBox';
import { useSelectedBusinessUserStore } from '#/screens/home/selectedBusinessUser.store';
import { formatPrice } from '#/utils';
import dayjs from 'dayjs';
import { View, Text } from 'react-native';
import styles from './adminProfile.module.scss';

const Table = ({ data, label }: { data: any[]; label: string }) => {
  if (!data) {
    return null;
  }

  const mapTransactionToRow = (t: any) => {
    return (
      <View style={tableStyles.transactionRow} key={t.id}>
        <Text style={tableStyles.columnDate}>
          {dayjs(t.createdAt).format('DD MMMM, YYYY HH:mm:ss')}
        </Text>
        <Text style={tableStyles.columnDescription}>{t.description}</Text>
        <Text style={tableStyles.columnValue}>{formatPrice(t.value)}</Text>
        <Text style={tableStyles.columnBalance}>{formatPrice(t.balance)}</Text>
      </View>
    );
  };

  return (
    <div className={styles.tableWrapper}>
      <div className={styles.tableTitle}>{label}</div>
      <div className={styles.table}>
        <View style={[tableStyles.transactionRow, tableStyles.transactionHeadingRow]}>
          <Text style={tableStyles.columnDate}>Date</Text>
          <Text style={tableStyles.columnDescription}>Description</Text>
          <Text style={tableStyles.columnValue}>Value</Text>
          <Text style={tableStyles.columnBalance}>Balance</Text>
        </View>
        {data.map(mapTransactionToRow)}
      </div>
    </div>
  );
};

export const AdminProfileScreen = () => {
  const { selectedUserId, selectedUserLogin } = useSelectedBusinessUserStore();
  const { data, isPending } = userDetailsQuery(selectedUserId!, { enabled: !!selectedUserId });

  return (
    <PageWrapper>
      <Heading>User {selectedUserLogin}</Heading>
      {isPending || !data ? (
        <Loader size="large" />
      ) : (
        <div className={styles.wrapper}>
          <div className={styles.left}>
            <DetailsBox data={data} />
          </div>
          <div className={styles.right}>
            <Table data={data.lastTransactions} label="Last transactions" />
            <Table data={data.lastUsage} label="Last usage" />
          </div>
        </div>
      )}
    </PageWrapper>
  );
};
