import { useUsersQuery } from '#/api/api.portal';
import { useSessionStorage } from 'usehooks-ts';
import {
  ApiUserMonitoringDeviceState,
  UserMonitoringIngestState,
  UserMonitoringUserSessionState,
} from '#/api/types';
import { useEffect, useMemo } from 'react';
import { getDeviceId } from 'react-native-device-info';

export const useAdminUsersQuery = ({
  perPage,
  search = '',
}: {
  perPage: number;
  search?: string;
}) => {
  const [onlineOnly, setOnlineOnly] = useSessionStorage('al:onlineOnly', true);
  const [type, setType] = useSessionStorage<'standard' | 'business'>('al:type', 'standard');
  const [page, setCurrentPage] = useSessionStorage('al:page', 1);
  const { data, isPending } = useUsersQuery(
    {
      offset: (page - 1) * perPage,
      onlineOnly,
      limit: perPage,
      type,
      search,
    },
    {
      refetchInterval: 5000,
    },
  );

  useEffect(() => {
    setCurrentPage(1);
  }, [perPage]);

  const mappedUsers = useMemo(
    () =>
      data?.users?.map(u => ({
        ...u,
        isOnline:
          u.state === UserMonitoringUserSessionState.Online &&
          u.devices.some(
            d => d.id !== getDeviceId() && d.state === ApiUserMonitoringDeviceState.Online,
          ),
        isStreaming: u.ingest?.state === UserMonitoringIngestState.Ok,
      })),
    [data?.users],
  );

  return {
    users: mappedUsers,
    isPending,
    totalPages: Math.ceil((data?.total || 0) / perPage),
    type,
    page,
    onlineOnly,
    setOnlineOnly: (value: boolean) => {
      setOnlineOnly(value);
      setCurrentPage(1);
    },
    setCurrentPage,
    toggleType: () => {
      setType(type === 'business' ? 'standard' : 'business');
      setCurrentPage(1);
    },
  };
};
