import React, { useContext, useEffect, useState } from 'react';
import { View, Text } from 'react-native';

import Button from '../../components/Button/Button';
import styles from './Tariff.styles';
import type {
  ActiveTariff,
  BusinessTariff,
  Me,
  PaykickstartProduct,
  Tariff,
} from '../../api/types';
import DefaultModal from '../../components/DefaultModal/DefaultModal';
import { ToasterContext } from '../../toaster/toaster.provider';
import dayjs from 'dayjs';
import mixins from '../../app/styles';
import { cleanName, WithPK } from './Tariff.utils';
import { useIsMobile } from '../../utils';
import TopUpModal from './TopUpModal';
import PaykickstartModal from './PaykickstartModal';
import CurrentTariffInfoBox from '../../components/CurrentTariffInfoBox';
import { useAppNavigation, useAppRoute } from '#/navigation/navigation.ref';
import { TariffBox } from '#/screens/tariff/TariffBox/TariffBox';
import { FeaturesBox } from '#/screens/tariff/FeaturesBox/FeaturesBox';
import { InputWithLabel } from '#/components/InputWIthLabel/InputWIthLabel';
import { useRedeemCodeMutation } from '#/api/api.portal';

interface Props<T> {
  customTopUpFunnelId?: number | null;
  tariffs: T[];
  myTariff: Pick<ActiveTariff, 'name' | 'id' | 'validTo'> | ActiveTariff;
  deposits?: PaykickstartProduct[];
  me?: Me;

  onChangeTariff: (tariff: T, method: 'CRYPTO' | 'CREDIT') => Promise<void>;
}

export const TariffsWrapper = <T extends WithPK<Tariff> | BusinessTariff>({
  customTopUpFunnelId,
  tariffs,
  myTariff,
  deposits,
  me,
  onChangeTariff,
}: Props<T>) => {
  const isMobile = useIsMobile();
  const [isUpgradeTariffVisible, setUpgradeTariffVisibility] = useState(false);
  const [code, setCode] = useState('');
  const { mutateAsync: redeemCode, isPending: redeemCodeLoading } = useRedeemCodeMutation();
  const [requestPending, setRequestPending] = useState(false);
  const [topUpModalVisibility, setTopUpModalVisibility] = useState(false);
  const [isBalanceTooLow, setIsBalanceTooLow] = useState(false);
  const [newTariffChosen, setNewTariffToActivate] = useState<T>();
  const [type, filterByType] = useState<'MONTH' | 'HOUR'>('MONTH');
  const navigation = useAppNavigation();
  const route = useAppRoute<'Tariff'>();
  const { error } = useContext(ToasterContext);

  useEffect(() => {
    if (route.params?.openTopUp) {
      setTopUpModalVisibility(true);
    }
  }, [route.params?.openTopUp]);

  useEffect(() => {
    if (!myTariff) {
      return;
    }

    const tariff = tariffs.find(t => t.id === myTariff.id);

    if (!tariff) {
      return;
    }

    filterByType(tariff.server > 0 ? 'HOUR' : 'MONTH');
  }, [filterByType, myTariff, tariffs]);

  const handleChangeTariff = async (method: 'CRYPTO' | 'CREDIT' = 'CREDIT') => {
    if (!newTariffChosen) {
      return;
    }

    try {
      setRequestPending(true);

      await onChangeTariff(newTariffChosen, type === 'HOUR' ? 'CREDIT' : method);
    } catch (err: any) {
      if (err.message.toLowerCase().includes('balance is too low')) {
        setIsBalanceTooLow(true);
        setTopUpModalVisibility(true);
      }
      error({ message: String(err.message), title: 'Could not change pricing plan' });
    } finally {
      setRequestPending(false);
      setNewTariffToActivate(undefined);
    }
  };

  const handleRedeemCode = async () => {
    await redeemCode(code);
    setCode('');
    setUpgradeTariffVisibility(false);
  };

  return (
    <>
      <View style={{ maxWidth: 500 }}>
        {'paymentMethod' in myTariff ? (
          <CurrentTariffInfoBox tariff={myTariff} />
        ) : (
          myTariff.validTo && (
            <Text style={styles.basicTariffInfo}>
              Current tariff expires in {dayjs(myTariff.validTo).diff(dayjs(), 'day')} days
            </Text>
          )
        )}
      </View>
      {isMobile && <FeaturesBox />}
      <View style={styles.buttonLine}>
        <Button
          style={[type !== 'MONTH' && styles.buttonInactive, styles.typeButton]}
          onPress={() => filterByType('MONTH')}
        >
          Per month
        </Button>
        <Button
          style={[type !== 'HOUR' && styles.buttonInactive, styles.typeButton]}
          onPress={() => filterByType('HOUR')}
        >
          Per hour
        </Button>
      </View>
      <View style={styles.plansRow}>
        {tariffs
          .filter(
            tariff =>
              (type === 'MONTH' ? tariff.serverMonthly > 0 : tariff.server > 0) ||
              (tariff.server === 0 && tariff.serverMonthly === 0),
          )
          .map(tariff => (
            <TariffBox
              key={tariff.id}
              tariff={tariff}
              isActive={myTariff?.id === tariff.id}
              isMonthlyViewActive={type === 'MONTH'}
              onTariffSelected={setNewTariffToActivate}
              onUpgradeTariff={() => setUpgradeTariffVisibility(true)}
            />
          ))}
      </View>
      {!isMobile && <FeaturesBox />}
      {topUpModalVisibility && customTopUpFunnelId && (
        <TopUpModal
          me={me}
          deposits={deposits}
          funnelId={customTopUpFunnelId}
          isBalanceTooLow={isBalanceTooLow}
          onClose={() => {
            setTopUpModalVisibility(false);
            navigation.navigate('Tariff');
          }}
        />
      )}
      {newTariffChosen &&
        'PaykickstartProduct' in newTariffChosen &&
        newTariffChosen.PaykickstartProduct && (
          <PaykickstartModal
            onClose={() => setNewTariffToActivate(undefined)}
            onChangeTariff={handleChangeTariff}
            myTariff={myTariff}
            allowCredit
            paykickstartElement={newTariffChosen.el}
            tariffId={newTariffChosen.id}
          />
        )}
      {newTariffChosen &&
        (!('PaykickstartProduct' in newTariffChosen) || !newTariffChosen.PaykickstartProduct) && (
          <DefaultModal
            confirmText={requestPending ? 'Changing...' : 'Change'}
            dismissText="Cancel"
            title="Confirm changing pricing plan"
            rawContent
            onConfirm={requestPending ? () => {} : () => handleChangeTariff('CREDIT')}
            onDismiss={() => setNewTariffToActivate(undefined)}
          >
            {myTariff?.validTo && (
              <View style={styles.currentTariff}>
                <Text style={[styles.modalText, mixins.styles.margin.topI7]}>
                  You have currently enabled pricing plan:{' '}
                  <Text style={styles.modalTextBold}>{cleanName(myTariff.name)}</Text>
                </Text>
                <Text style={styles.modalText}>
                  Your subscription is valid to:{' '}
                  <Text style={styles.modalTextBold}>
                    {dayjs(myTariff!.validTo).format('YYYY-MM-DD')}
                  </Text>
                </Text>
                <Text style={[styles.modalText, mixins.styles.margin.topI7]}>
                  Changing pricing plan will cancel previous one.
                </Text>
              </View>
            )}
            <Text style={styles.modalText}>
              Do you really want to change your pricing plan to:{' '}
              <Text style={styles.modalTextBold}>{cleanName(newTariffChosen.name)}</Text>
            </Text>
          </DefaultModal>
        )}
      {isUpgradeTariffVisible && (
        <DefaultModal
          confirmText="Upgrade"
          confirmLoading={redeemCodeLoading}
          onConfirm={handleRedeemCode}
          onDismiss={() => setUpgradeTariffVisibility(false)}
          title="Upgrade your pricing plan tier"
          dismissText="Cancel"
        >
          <Text style={styles.modalText}>
            Please provide coupon code to upgrade tier of your plan.
          </Text>
          <View style={styles.modalInput}>
            <InputWithLabel value={code} onChange={setCode} name="Coupon code"></InputWithLabel>
          </View>
        </DefaultModal>
      )}
    </>
  );
};
