import { useBusinessUsersQuery, useMeQuery, useServerStatsQuery } from '#/api/api.portal';
import dayjs from 'dayjs';

import {
  ApiUserMonitoring,
  ApiUserMonitoringDeviceState,
  BusinessUser,
  Tariff,
  UserMonitoringIngestState,
  UserMonitoringUserSessionState,
} from '#/api/types';
import { useMemo } from 'react';
import { getDeviceId } from '#/utils';

export const studForUser = (user: string) =>
  ({
    server: '',
    user: user,
    state: 0,
    start: '',
    stop: null,
    ingest: undefined,
    channels: [],
    webRtcPage: null,
    webRtcUrl: null,
    devices: [],
  } as ApiUserMonitoring);

export type BusinessUserListItem = ApiUserMonitoring &
  BusinessUser & { admin?: true; isOnline: boolean; isStreaming: boolean };

export const useBusinessUsers = (order: BusinessUserOrderOptionsType) => {
  const { data: stats = [] } = useServerStatsQuery();
  const { data: users } = useBusinessUsersQuery();
  const { data: profile } = useMeQuery();

  const userList = useMemo(() => {
    if (!users || !profile) {
      return [];
    }

    const list = (
      [
        ...users.map(u => ({
          ...u,
          ...(stats.find(u2 => u.login === u2.user) || studForUser(u.login)),
        })),
        {
          ...(stats.find(u => u.user === profile.login)! || studForUser(profile.login)),
          id: profile.userId,
          login: profile.login,
          admin: true,
        },
      ] as BusinessUserListItem[]
    ).map(u => ({
      ...u,
      isOnline:
        u.state === UserMonitoringUserSessionState.Online &&
        u.devices.some(
          d => d.id !== getDeviceId() && d.state === ApiUserMonitoringDeviceState.Online,
        ),
      isStreaming: u.ingest?.state === UserMonitoringIngestState.Ok,
    }));

    if (order === 'name') {
      list.sort((a, z) => a.login.localeCompare(z.login, undefined, { numeric: true }));
    }

    if (order === 'expiration') {
      list.sort((a, z) => {
        if (!a.tariffExpiresAt) {
          return 1;
        }
        if (!z.tariffExpiresAt) {
          return -1;
        }
        return dayjs(a.tariffExpiresAt).diff(dayjs(z.tariffExpiresAt));
      });
    }

    if (order === 'state') {
      list.sort((z, a) => a.state - z.state);
    }

    return list;
  }, [stats, users, profile, order]);

  return { userList, users, stats, profile };
};

export const businessUsersOrderOptions = [
  { label: 'by name', value: 'name' },
  { label: 'by state', value: 'state' },
  { label: 'by expiration date', value: 'expiration' },
] as const;

export type BusinessUserOrderOptionsType = (typeof businessUsersOrderOptions)[number]['value'];

export const getTariffsForUser = (tariffs: Tariff[], user: BusinessUser) =>
  tariffs.filter(
    t =>
      t.id === user.tariffId ||
      t.type === 'standard' ||
      user.customTariffIds.some(ct => ct === t.id),
  );
