export interface Me {
  userId: number;
  login: string;
  email: string;
  tariffId: number;
  balance: number;
  active: boolean;
  business: boolean;
  enoughBalance: boolean;
  admin: boolean;
}

export interface Tariff {
  id: number;
  name: string;
  server: number;
  serverMonthly: number;
  type: string;
  order: number;
  description: string;
  createdAt: string;
  updatedAt: string;
  productId?: number;
  PaykickstartProduct?: PaykickstartProduct;
  appSumoTier: null | 1 | 2 | 3;
}

export interface ActiveTariff {
  id: number;
  name: string;
  server: number;
  serverMonthly: number;
  type: string;
  order: number;
  description: string;
  createdAt: string;
  updatedAt: string;
  productId?: any;
  validTo?: string;
  notValid?: boolean;
  willRenew: boolean;
  paymentMethod: PaymentMethod;
}

export interface ReferralStats {
  usersRegistered: number;
  currentBalance: number;
  paidUsers: number;
  earnedTotal: number;
  code: string;
  url: string;
  history: Array<{
    createdAt: string;
    type: 'REGISTRATION' | 'PROVISION' | 'TRANSFER' | 'WITHDRAWAL';
    value: number;
    description: string;
    balance: number;
    referredUser?: { login: string };
  }>;
}

export interface PaykickstartProduct {
  id: number;
  productId: number;
  funnelId: number;
  name: string;
  value?: number;
  createdAt: string;
  updatedAt: string;
  tariffId: number;
}

export interface UsageData {
  time: string;
  value: number;
}

export interface TransactionsResponse {
  transactions: Transaction[];
  pagination: Pagination;
}

export interface Payment {
  id: number;
  value: number;
  description: string;
  method: string;
  createdAt: string;
}

export interface PaymentsResponse {
  payments: Payment[];
  pagination: Pagination;
}

export interface PaymentStatusResponse {
  processor: PaymentProcessor;
  type: 'tariff-monthly' | 'top-up';
  isPending: number;
  isApproved: number;
  isProcessed: boolean;
}

type PaymentMethod = 'balance' | 'card' | 'paypal' | 'crypto';

type PaymentProcessor =
  | 'paypalmarketplace'
  | 'coinbase'
  | 'free'
  | 'stripe'
  | 'offline'
  | 'testmode';

export interface Pagination {
  page: number;
  pageCount: number;
}

export interface Transaction {
  id: number;
  userId: number;
  status: string;
  type: string;
  description: string;
  value: number;
  balance: number;
  data: TransactionData;
  createdAt: string;
  updatedAt: string;
}

export interface TransactionData {
  entry: TransactionEntry;
}

export interface TransactionEntry {
  Id: string;
  User: string;
  Duration: number;
  StartTime: string;
  Transferred: number;
}

export interface BusinessUser {
  id: number;
  login: string;
  createdAt: string;
  updatedAt: string;
  businessTariffId: number;
  tariffExpiresAt: string | null;
  usageCost: number;
  usageMinutes: number;
  tariffId: number;
  tariffName?: string;
  customTariffIds: number[];
}

export interface BusinessTariff {
  id: number;
  serverMonthly: number;
  server: number;
  type: string;
  period: 'monthly' | 'hourly';
  name: string;
  displayName: string;
  description: string;
  appSumoTier: null;
}

export interface BusinessUserAccessTokenResponse {
  access_token: string;
  expires_in: number;
  token_type: string;
  scope: string;
}

// STATS

export interface ApiUserMonitoring {
  user: string;
  state: UserMonitoringUserSessionState;
  server: string;
  start: string;
  stop: string | null;
  ingest?: ApiUserMonitoringIngest;
  channels: ApiUserMonitoringChannel[];
  devices: ApiUserMonitoringDevice[];
  webRtcPage: string | null;
  webRtcUrl: string | null;
}

export enum UserMonitoringUserSessionState {
  Offline = 0,
  Online = 1,
  Other = 2,
}

export interface ApiUserMonitoringDevice {
  id: string;
  type: 'web' | 'and' | 'ios' | 'win';
  version: string;
  state: ApiUserMonitoringDeviceState;
}

export enum ApiUserMonitoringDeviceState {
  Offline = 0,
  Online = 1,
}

export interface ApiUserMonitoringIngest {
  type: string;
  state: UserMonitoringIngestState;
  bitrate: number;
}

export interface ApiUserMonitoringChannel {
  name: string;
  state: UserMonitoringChannelState;
  transcoder: number;
  bitrate: number;
}

export enum UserMonitoringIngestState {
  Offline = 0,
  Ok = 1,
  Error = 2,
}

export enum UserMonitoringChannelState {
  Off = 0,
  Ok = 1,
  Error = 2,
  Warning = 3,
}
