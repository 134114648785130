import { useContext } from 'react';
import { AuthContext, getAuthStore } from '../screens/auth/auth.utils';
import { UNIQUE_ID, useFetch, UseFetchOpts } from '../utils';
import api from './api';
import { API_HOST_URL } from './api.const';
import type {
  ActiveTariff,
  Me,
  PaykickstartProduct,
  PaymentStatusResponse,
  Tariff,
  TransactionsResponse,
  UsageData,
  ApiUserMonitoring,
  BusinessUser,
  BusinessTariff,
  BusinessUserAccessTokenResponse,
  ReferralStats,
} from './types';
import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { ToasterContext } from '#/toaster/toaster.provider';
import { useSelectedBusinessUserStore } from '#/screens/home/selectedBusinessUser.store';
import { navigationRef } from '#/navigation/navigation.ref';
import { PermissionError } from '#/error/error';

const fetchQuery = <T>(url: string, actingUser = true): Partial<UseQueryOptions> => ({
  queryKey: [url],
  queryFn: async () => {
    try {
      const store = getAuthStore();
      const selectedUserId =
        useSelectedBusinessUserStore.getState().selectedOwnerId ||
        useSelectedBusinessUserStore.getState().selectedUserId;

      const [response] = await api(
        `${API_HOST_URL}/${url}`,
        { method: 'GET' },
        {
          ...(store?.webAuth?.accessToken && {
            Authorization: `Bearer ${store.webAuth.accessToken}`,
            ...(actingUser && selectedUserId && { 'X-Acting-User': selectedUserId }),
          }),
        },
      );

      return response.data as T;
    } catch (err) {
      if (err instanceof PermissionError) {
        navigationRef.current?.navigate('Connect', { refresh: true });
      }
    }
  },
  staleTime: 10000,
});

type Opts = Omit<UseQueryOptions, 'queryFn' | 'queryKey'>;

const useReactQuery =
  <T>(url: string) =>
  (opts?: Opts) =>
    useQuery({ ...fetchQuery<T>(url), ...opts } as UseQueryOptions<T>);

const useAdminReactQuery =
  <T>(url: string) =>
  (opts?: Opts) =>
    useQuery({ ...fetchQuery<T>(url, false), ...opts } as UseQueryOptions<T>);

export const useUsersQuery = (
  {
    search,
    type,
    offset,
    limit,
    onlineOnly,
  }: {
    search: string;
    offset: number;
    limit: number;
    onlineOnly: boolean;
    type: 'business' | 'standard';
  },
  opts?: Opts,
) =>
  useAdminReactQuery<{ users: any[]; total: number }>(
    `admin/users?offset=${offset}&onlineOnly=${onlineOnly}&limit=${limit}&type=${type}&search=${search}`,
  )(opts);

export const useAdminOnlineUsersQuery = useAdminReactQuery<string[]>('admin/users/online');

export const userDetailsQuery = (userId: number, opts?: Opts) =>
  useAdminReactQuery<any>(`admin/users/${userId}`)(opts);

export const useAdminTariffsQuery = useAdminReactQuery<Tariff[]>('admin/tariffs');

export const useMeQuery = useReactQuery<Me>('me');
export const useMyTariffQuery = useReactQuery<ActiveTariff>('me/tariff');
export const useReferralStatsQuery = useReactQuery<ReferralStats>('me/referral-stats');
export const useDepositsQuery = useReactQuery<PaykickstartProduct[]>('deposits');
export const useTariffsQuery = useReactQuery<Tariff[]>('tariffs');
export const useCouponTariffQuery = (code: string, opts?: Opts) =>
  useReactQuery<Tariff>(`coupon-tariff?code=${code}`)(opts);
export const useUsageDailyQuery = useReactQuery<UsageData[]>('me/usage/month');
export const useUsageMonthlyQuery = useReactQuery<UsageData[]>('me/usage/year');
export const useBusinessUsersQuery = useReactQuery<BusinessUser[]>('business/users');
export const useBusinessUserQuery = (id: number, opts?: Opts) =>
  useReactQuery<BusinessUser>(`business/users/${id}`)(opts);

export const useBusinessUsageDailyQuery = (opts?: Opts) =>
  useReactQuery<UsageData[]>(`business/usage/month`)(opts);
export const useBusinessUsageMonthlyQuery = (opts?: Opts) =>
  useReactQuery<UsageData[]>(`business/usage/year`)(opts);
export const useBusinessTransactionsQuery = (page: number, opts?: Opts) =>
  useReactQuery<TransactionsResponse>(`business/transactions?page=${page}`)(opts);

export const useBusinessUserUsageDailyQuery = (id: number, opts?: Opts) =>
  useReactQuery<UsageData[]>(`business/users/${id}/usage/month`)(opts);
export const useBusinessUserUsageMonthlyQuery = (id: number, opts?: Opts) =>
  useReactQuery<UsageData[]>(`business/users/${id}/usage/year`)(opts);
export const useBusinessUserTransactionsQuery = (id: number, page: number, opts?: Opts) =>
  useReactQuery<TransactionsResponse>(`business/users/${id}/transactions?page=${page}`)(opts);

export const useBusinessTariffsQuery = useReactQuery<Tariff[]>('business/tariffs');
export const useCustomTopUpFunnelQuery = useReactQuery<{ funnelId: number | null }>(
  'top-up-funnel',
);

export const useServerStatsQuery = () =>
  useQuery({
    queryKey: ['server-stats'],
    refetchInterval: 3000,
    queryFn: async () => {
      const store = getAuthStore();

      const [response] = await api(
        `Statistics/Users`,
        { method: 'GET', port: 6001 },
        {
          ...(store?.accessToken && {
            Authorization: `Bearer ${store.accessToken}`,
          }),
        },
      );

      return response.data as ApiUserMonitoring[];
    },
  });

export const useTransactionsQuery = (page = 1, opts?: Opts) =>
  useReactQuery<TransactionsResponse>(`me/transactions?page=${page}`)(opts);
export const usePaymentTransactions = (page = 1, opts?: Opts) =>
  useReactQuery<PaymentResponse>(`me/payments?page=${page}`)(opts);
export const useTransactionStateQuery = (id: string, productId: string, opts?: UseFetchOpts) =>
  useFetch<PaymentStatusResponse>(`payment?paymentId=${id}&productId=${productId}`, opts);
export const useChaportIdQuery = (opts?: UseFetchOpts) =>
  useFetch<{ id: string | null; hash: string }>('chaport-id', opts);

const getRequestHeaders = ({ ignoreActingUser }: { ignoreActingUser?: boolean } = {}) => {
  const { webAuth } = useContext(AuthContext);
  const selectedUserId =
    useSelectedBusinessUserStore.getState().selectedOwnerId ||
    useSelectedBusinessUserStore.getState().selectedUserId;

  return {
    ...(webAuth && { Authorization: `Bearer ${webAuth!.accessToken}` }),
    ...(selectedUserId && !ignoreActingUser && { 'X-Acting-User': selectedUserId }),
  };
};

export const useChangePasswordRequest = () => {
  const headers = getRequestHeaders();

  return [
    async (password: string) => {
      const [response] = await api(
        `${API_HOST_URL}/update-password`,
        { method: 'POST', body: { password, confirmPassword: password } },
        headers,
      );

      return response;
    },
  ];
};

export const useChangeBusinessUserPasswordRequest = () => {
  const headers = getRequestHeaders();

  return [
    async (userId: number, password: string) => {
      const [response] = await api(
        `${API_HOST_URL}/business/users/${userId}/change-password`,
        { method: 'POST', body: { password } },
        headers,
      );

      return response;
    },
  ];
};

export const useChangeTariffRequest = () => {
  const headers = getRequestHeaders();

  return [
    async (tariffId: number) => {
      const [response] = await api(
        `${API_HOST_URL}/me/change-tariff`,
        { method: 'POST', body: { tariffId } },
        headers,
      );

      return response;
    },
  ];
};

export const useCancelTariffRenewalRequest = () => {
  const headers = getRequestHeaders();

  return [
    async () => {
      const [response] = await api(
        `${API_HOST_URL}/me/cancel-renewal`,
        { method: 'POST', body: {} },
        headers,
      );

      return response;
    },
  ];
};

export const useCompleteProfileRequest = () => {
  const headers = getRequestHeaders();

  return [
    async (body: { type: string; region: string }) => {
      const [response] = await api(
        `${API_HOST_URL}/api/setup-camone`,
        { method: 'POST', body },
        headers,
      );

      return response;
    },
  ];
};

export const useStoreChaportIdRequest = () => {
  const headers = getRequestHeaders();

  return [
    async (body: { id: string }) => {
      const [response] = await api(`${API_HOST_URL}/chaport-id`, { method: 'POST', body }, headers);

      return response;
    },
  ];
};

export const useBusinessUserTokenRequest = (args: {
  adminRequest?: boolean;
  tokenType: 'overlays' | 'server' | 'chats';
}) => {
  const { adminRequest = false, tokenType } = args;
  const headers = getRequestHeaders({ ignoreActingUser: true });

  const urlType = adminRequest ? 'admin' : 'business';
  const instanceId = `${tokenType === 'chats' ? 'C_' : 'M_'}${UNIQUE_ID}`;

  return [
    async (id: number, login: string) => {
      const [response] = await api<BusinessUserAccessTokenResponse>(
        `${API_HOST_URL}/${urlType}/users/${
          login || id
        }/token?iid=${instanceId}&tokenType=${tokenType}`,
        { method: 'GET' },
        headers,
      );

      return response;
    },
  ];
};

export const useMigrateToBusinessMutation = (opts?: Omit<UseMutationOptions, 'mutationFn'>) => {
  const headers = getRequestHeaders();
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    onSuccess: (d, v, c) => {
      queryClient.invalidateQueries({ queryKey: ['me'] });

      opts?.onSuccess?.(d, v, c);
    },
    mutationFn: async () => {
      const [response] = await api(
        `${API_HOST_URL}/me/upgrade-business`,
        { method: 'POST' },
        headers,
      );

      return response;
    },
  });
};

export const useAddBusinessUserMutation = () => {
  const headers = getRequestHeaders();
  const { error } = useContext(ToasterContext);
  const queryClient = useQueryClient();

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['business/users'] });
    },
    onError: (err, variables, context) => {
      error({ message: err.message });
    },
    mutationFn: async (body: { login: string; password: string; tariffId: number }) => {
      const [response] = await api(
        `${API_HOST_URL}/business/users`,
        { method: 'POST', body },
        headers,
      );

      return response;
    },
  });
};

export const useDeleteBusinessUserMutation = () => {
  const headers = getRequestHeaders();
  const { error } = useContext(ToasterContext);
  const queryClient = useQueryClient();

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['business/users'] });
    },
    onError: (err, variables, context) => {
      error({ message: err.message });
    },
    mutationFn: async (userId: number) => {
      const [response] = await api(
        `${API_HOST_URL}/business/users/${userId}`,
        { method: 'DELETE' },
        headers,
      );

      return response;
    },
  });
};

export const useRedeemCodeMutation = () => {
  const headers = getRequestHeaders();
  const { error, success } = useContext(ToasterContext);
  const queryClient = useQueryClient();

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['tariffs'] });

      success({ message: 'Code has been redeemed successfully' });
    },
    onError: err => {
      error({ message: err.message });
    },
    mutationFn: async (code: string) => {
      const [response] = await api(
        `${API_HOST_URL}/me/redeem-code`,
        { method: 'POST', body: { code } },
        headers,
      );

      return response;
    },
  });
};

export const useChangeBusinessTariffRequest = () => {
  const headers = getRequestHeaders();
  const { error } = useContext(ToasterContext);
  const queryClient = useQueryClient();

  return useMutation({
    onSuccess: (d, v, c) => {
      queryClient.invalidateQueries({ queryKey: ['business/users'] });
    },
    onError: err => {
      error({ message: err.message });
    },
    mutationFn: async ({ userId, tariffId }: { userId: number; tariffId: number }) => {
      const [response] = await api(
        `${API_HOST_URL}/business/users/${userId}/change-tariff`,
        { method: 'POST', body: { tariffId } },
        headers,
      );

      return response;
    },
  });
};

export const useBillingPortalUrlRequest = (opts?: Omit<UseMutationOptions, 'mutationFn'>) => {
  const headers = getRequestHeaders();
  const { error } = useContext(ToasterContext);

  return useMutation({
    ...opts,
    onSuccess: (d, v, c) => {
      opts?.onSuccess?.(d, v, c);
    },
    onError: (err: any, variables, context) => {
      if (opts?.onError) {
        return opts.onError(err, variables, context);
      }

      error({ message: err.message });
    },
    mutationFn: async () => {
      const [response] = await api(
        `${API_HOST_URL}/me/billing-portal-url`,
        { method: 'GET' },
        headers,
      );

      return response as { data: { url: string } };
    },
  });
};

export const useTopUpWithCryptoRequest = () => {
  const headers = getRequestHeaders();
  const { error } = useContext(ToasterContext);

  return useMutation({
    onError: (err: any) => {
      error({ message: err.message });
    },
    mutationFn: async (value: number) => {
      const [response] = await api(
        `${API_HOST_URL}/coingate/topup`,
        { method: 'POST', body: { value } },
        headers,
      );

      return response as { data: { redirect: string } };
    },
  });
};

export const useChangeTariffWithCryptoRequest = () => {
  const headers = getRequestHeaders();
  const { error } = useContext(ToasterContext);

  return useMutation({
    onError: (err: any) => {
      error({ message: err.message });
    },
    mutationFn: async (tariffId: number) => {
      const [response] = await api(
        `${API_HOST_URL}/coingate/tariff`,
        { method: 'POST', body: { tariffId } },
        headers,
      );

      return response as { data: { redirect: string } };
    },
  });
};

export const useWithdrawalRequestMutation = () => {
  const headers = getRequestHeaders();
  const { error } = useContext(ToasterContext);
  const queryClient = useQueryClient();

  return useMutation({
    onSuccess: (d, v, c) => {
      if (v.type === 'TRANSFER') {
        queryClient.invalidateQueries({ queryKey: ['me/referral-stats'] });
        queryClient.invalidateQueries({ queryKey: ['me'] });
      }
    },
    onError: (err: any) => {
      error({ message: err.message });
    },
    mutationFn: async (body: { value: number; type: 'WITHDRAWAL' | 'TRANSFER' }) => {
      const [response] = await api(
        `${API_HOST_URL}/me/request-withdrawal`,
        { method: 'POST', body },
        headers,
      );

      return response;
    },
  });
};
