import { usePaymentTransactions } from '#/api/api.portal';
import Heading from '#/components/Heading/Heading';
import PageWrapper from '#/components/PageWrapper/PageWrapper';
import { tableStyles } from './Billing.styles';
import { formatPrice } from '#/utils';
import { View, Text, Pressable } from 'react-native';
import styles from './Billing.module.scss';
import dayjs from 'dayjs';
import { Payment, Transaction } from '#/api/types';
import Loader from '#/components/Loader/Loader';
import mixins from '#/app/styles';
import { BusinessUserSwitcher } from '#/components/BusinessUserSwitcher/BusinessUserSwitcher';

const Table = ({ data }: { data: Payment[] }) => {
  if (!data) {
    return null;
  }

  const mapTransactionToRow = (t: Payment) => {
    return (
      <View style={tableStyles.transactionRow} key={t.id}>
        <Text style={tableStyles.columnDate}>
          {dayjs(t.createdAt).format('DD MMMM, YYYY HH:mm:ss')}
        </Text>
        <Text style={tableStyles.columnValue}>{formatPrice(t.value, { removeSign: true })}</Text>
        <Text style={tableStyles.columnMethod}>{t.method}</Text>
        <Text style={tableStyles.columnDescription}>{t.description}</Text>
        <Text style={tableStyles.columnInvoice}>
          <Pressable>Download</Pressable>
        </Text>
      </View>
    );
  };

  return (
    <div className={styles.tableWrapper}>
      <div className={styles.table}>
        <View style={[tableStyles.transactionRow, tableStyles.transactionHeadingRow]}>
          <Text style={tableStyles.columnDate}>Date</Text>
          <Text style={tableStyles.columnValue}>Amount</Text>
          <Text style={tableStyles.columnMethod}>Payment method</Text>
          <Text style={tableStyles.columnDescription}>Description</Text>
          <Text style={tableStyles.columnInvoice}>Invoice</Text>
        </View>
        {data.map(mapTransactionToRow)}
      </div>
    </div>
  );
};

export const BillingScreen = () => {
  const paymentTransactions = usePaymentTransactions();

  return (
    <PageWrapper>
      <BusinessUserSwitcher />
      <Heading style={mixins.styles.margin.topI8}>Billing history</Heading>
      {paymentTransactions?.isPending ? <Loader size="large" /> : null}
      {paymentTransactions.data && <Table data={paymentTransactions.data.transactions} />}
    </PageWrapper>
  );
};
